/*
 * @Author: gaojingran
 * @Date: 2021-04-01 13:22:38
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-31 13:37:12
 * @Description: 请求方法 mixin
 */

// import omit from 'lodash/omit'
import axios from '@/utils/request'

export default {
  created() {
    this.$httpDefers = []
    if (this.$store && this.$store.dispatch) {
      const dispatch = this.$store.dispatch
      this.$store.dispatch = (action, payload) => {
        dispatch(action, {
          $http: this.$http,
          $httpNotify: this.$httpNotify,
          ...payload,
        })
      }
    }
  },

  beforeDestroy() {
    while (this.$httpDefers.length) {
      try {
        this.$httpDefers.pop().cancel('canceled')
      } catch (e) {
        console.log('$httpDefers:', e)
      }
    }
  },

  methods: {
    /**
     * 🌰🌰🌰🌰
     * @param apiKey [string || object: { key, params, query }]
     *
     * 假设url 为 get::/project/info/{id}  params {formid: xxx}
     * this.$http({
     *  key: apiKey,
     *  params: {id: xxxxx}
     * }, {formid: xxx})
     * 假设url 为 post::/project/info/{id}?a=1&b=2  body {formid: xxx}
     * this.$http({
     *  key: apiKey,
     *  params: {
     *    id: xxxxx
     *  },
     *  query: {
     *    a: 1,
     *    b: 2
     *  }
     * }, {formid: xxx})
     * 普通url
     * this.$http(apiKey, payload)
     */
    $http(apiKey, payload, options = {}) {
      const cancel = options.cancelToken || axios.CancelToken.source()
      // 外部传入的canceltoken由外部手动cancel
      if (!options.cancelToken) {
        this.$httpDefers.push(cancel)
      }
      const { api } = window.$g
      let method = ''
      let url = ''

      if (typeof apiKey === 'object') {
        const { key, query = {}, params = {} } = apiKey
        let [_method, _url] = api[key].split('::')

        const _query = Object.entries(query)
          .reduce((acc, [k, v]) => {
            acc.push(`${k}=${v}`)
            return acc
          }, [])
          .join('&')

        Object.keys(params).forEach((_key) => {
          _url = _url.replace(`{${_key}}`, params[_key])
        })

        method = _method
        url = _query ? `${_url}?${_query}` : _url
      } else {
        ;[method, url] = api[apiKey].split('::')
      }
      return axios({
        method,
        url,
        cancelToken: cancel.token,
        data: method !== 'get' ? payload : undefined,
        params: method === 'get' ? payload : undefined,
        ...options,
      })
    },

    $httpNotify(err) {
      if (err && !this.$is.Defined(err.code)) {
        console.log(err)
        return false
      }
      // 请求超时
      if (err.code === 'timeout') {
        return this.$notification.error({
          duration: 3,
          message: this.$t('http.title'),
          description: this.$t('http.timeout'),
        })
      }
      // 其他
      if (err.code !== 'canceled') {
        return this.$notification.error({
          duration: 3,
          message: this.$t('http.title'),
          description: err.msg ? err.msg : JSON.stringify(err),
        })
      }
      return false
    },
  },
}
