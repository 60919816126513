/* eslint-disable prettier/prettier */
// 定制化映射

const Routes = {
  Rockwell: {
    'order/newOrder': 'Rockwell/order/newOrder',
    'order/orderInfo': 'Rockwell/order/orderInfo',
    'project/list': 'Rockwell/project/list',
    'customerConfig/productLineDetail':
      'Rockwell/customerConfig/productLineDetail',
    payment: 'Rockwell/payment',
  },
  Stone: {
    'order/newOrder': 'Stone/order/newOrder',
    'order/orderInfo': 'Stone/order/orderInfo',
    'coConfig/home': 'Stone/coConfig/home',
    home: 'Stone/home',
    'project/list': 'Stone/project/list',
    payment: 'Stone/payment',
    'project/schedule': 'Stone/project/schedule',
  },
  Kingsoft: {
    'order/newOrder': 'Kingsoft/order/newOrder',
    'order/orderInfo': 'Kingsoft/order/orderInfo',
    'project/list': 'Kingsoft/project/list',
    payment: 'Kingsoft/payment',
  },
};

const getRouteKey = key => {
  return Object.keys(Routes[key]).map(__ => {
    if (!__.includes('/')) {
      return __;
    } else {
      return `${__.split('/')[0]}_${__.split('/')[1]}`;
    }
  });
};

const Mapping = new Map([
  [
    'Rockwell',
    {
      name: 'Rockwell',
      ids: [],
      route: Routes['Rockwell'],
      routeKey: getRouteKey('Rockwell'),
      // routeKey: Object.keys(Routes['Rockwell']).map(
      //   __ => `${__.split('/')[0]}_${__.split('/')[1]}`
      // ),
      api: {
        processPresent: 'submitOrder',
        processSave: 'saveDraftbox',
        orderProcessInfo: 'orderProcessInfo2',
        submitFinalDraft: 'submitFinalDraft2',
        orderList: 'orderListRockwell',
        productLineConfigSave: 'productLineConfigSaveRockWell',
        fetchProductLineInfo: 'fetchProductLineInfo2',
      },
      // 要挂载在this.$g的变量全部放在mounts对象里，页面初始化后统一挂载
      mounts: {
        lang: [
          'ja_JP',
          'pt_BR',
          'zh_CN',
          'de_DE',
          'fr_FR',
          'it_IT',
          'ko_KR',
          'es_ES',
        ],
        taskTypes: ['', 'MTPE', 'TEP'],
        docTypes: {
          软件界面词类: 'GUI',
          文档类: 'DOC',
        },
      },
    },
  ],
  [
    'Stone',
    {
      name: 'Stone',
      ids: [],
      route: Routes['Stone'],
      routeKey: getRouteKey('Stone'),
      api: {
        submitFinalDraft: 'submitFinalDraftStone',
        orderStatisticTodoCount: 'stoneTodoCount',
      },
      mounts: {
        order_manager_info_template:
          'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E4%B8%8B%E5%8D%95%E7%BB%8F%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-%E7%9F%B3%E5%A4%B4%E5%AE%9A%E5%88%B6%E7%89%88.xlsx',
      },
    },
  ],
  [
    'Kingsoft',
    {
      name: 'Kingsoft',
      ids: [],
      route: Routes['Kingsoft'],
      routeKey: getRouteKey('Kingsoft'),
      api: {
        orderProcessQuoteInfo:'kingsoftProcessQuoteInfo',
        orderProcessQuote: 'kingsoftProcessQuote',
      },
      mounts: {
        // order_manager_info_template:
        // 'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E4%B8%8B%E5%8D%95%E7%BB%8F%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-%E7%9F%B3%E5%A4%B4%E5%AE%9A%E5%88%B6%E7%89%88.xlsx',
      },
    },
  ],
]);

window.Mapping = Mapping;

export default Mapping;
