/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:27:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 14:54:04
 * @Description: 前端所需维护的字典表
 */
import i18n from '@/i18n'

export default {
  //  预警状态 1-紧急 0-预警
  warningTagDict: {
    1: i18n.t('dict.warning_tag_1'),
    0: i18n.t('dict.warning_tag_2'),
    null: i18n.t('dict.warning_tag_3'),
  },
  warningTagType: {
    1: 'danger',
    0: 'warning',
    null: 'primary',
  },

  /**
   * 订单状态
    dict.order_status1 = '等待报价'
    dict.order_status2 = '待确认报价'
    dict.order_status3 = '已取消'
    dict.order_status4 = '生产中'
    dict.order_status5 = '待确认终稿'
    dict.order_status6 = '终稿修改中'
    dict.order_status8 = '已终止'
    dict.order_status9 = '已确认终稿'
   * */
  orderStatusSelect: {
    1: i18n.t('dict.order_status1'),
    2: i18n.t('dict.order_status2'),
    4: i18n.t('dict.order_status4'),
    5: i18n.t('dict.order_status5'),
    6: i18n.t('dict.order_status6'),
    9: i18n.t('dict.order_status9'),
    3: i18n.t('dict.order_status3'),
    8: i18n.t('dict.order_status8'),
  },
  orderStatus: {
    1: i18n.t('dict.order_status1'),
    2: i18n.t('dict.order_status2'),
    3: i18n.t('dict.order_status3'),
    4: i18n.t('dict.order_status4'),
    5: i18n.t('dict.order_status5'),
    6: i18n.t('dict.order_status6'),
    7: i18n.t('dict.order_status5'),
    8: i18n.t('dict.order_status8'),
    9: i18n.t('dict.order_status9'),
  },

  /***
   * 结算状态
    dict.payment_status1 = '待下单方确认结算'
    dict.payment_status2 = '待供应商确认结算'
    dict.payment_status3 = '供应商驳回'
    dict.payment_status4 = '供应商已确认'
    dict.payment_status5 = '已开票'
  */
  paymentStatusSelect: {
    1: i18n.t('dict.payment_status1'),
    2: i18n.t('dict.payment_status2'),
    3: i18n.t('dict.payment_status3'),
    4: i18n.t('dict.payment_status4'),
    5: i18n.t('dict.payment_status5'),
  },
  paymentStatus: {
    1: i18n.t('dict.payment_status1'),
    2: i18n.t('dict.payment_status2'),
    3: i18n.t('dict.payment_status3'),
    4: i18n.t('dict.payment_status4'),
    5: i18n.t('dict.payment_status5'),
  },

  /***
   * 订单进度
    dict.orderProgress_status1 = '提交订单'
    dict.orderProgress_status2 = '提交报价单'
    dict.orderProgress_status3_1 = '拒绝报价，订单取消'
    dict.orderProgress_status3_2 = '订单取消'
    dict.orderProgress_status4 = '确认报价单'
    dict.orderProgress_status5 = '提交终稿'
    dict.orderProgress_status6 = '提交反馈修改'
    dict.orderProgress_status7 = '提交修改稿'
    dict.orderProgress_status8 = '终止订单'
    dict.orderProgress_status9 = '已确认终稿'
    dict.orderProgress_next_status1 = '等待供应商报价'
    dict.orderProgress_next_status2 = '待确认报价'
    dict.orderProgress_next_status4 = '生产中'
    dict.orderProgress_next_status5 = '待确认终稿'
    dict.orderProgress_next_status6 = '终稿修改中'
    dict.orderProgress_next_status7 = '待确认终稿'
  */
  orderProgressStatus: {
    1: i18n.t('dict.orderProgress_status1'),
    2: i18n.t('dict.orderProgress_status2'),
    '3_1': i18n.t('dict.orderProgress_status3_1'),
    '3_2': i18n.t('dict.orderProgress_status3_2'),
    4: i18n.t('dict.orderProgress_status4'),
    5: i18n.t('dict.orderProgress_status5'),
    6: i18n.t('dict.orderProgress_status6'),
    7: i18n.t('dict.orderProgress_status7'),
    8: i18n.t('dict.orderProgress_status8'),
    9: i18n.t('dict.orderProgress_status9'),
    '1_next': i18n.t('dict.orderProgress_next_status1'),
    '2_next': i18n.t('dict.orderProgress_next_status2'),
    '4_next': i18n.t('dict.orderProgress_next_status4'),
    '5_next': i18n.t('dict.orderProgress_next_status5'),
    '6_next': i18n.t('dict.orderProgress_next_status6'),
    '7_next': i18n.t('dict.orderProgress_next_status7'),
  },
  // 对接系统：1 对接Neotrans1.0, 2 对接新版Neotran, 0 不对接生产系统
  systemDict: {
    0: i18n.t('dict.system_0'),
    1: i18n.t('dict.system_1'),
    2: i18n.t('dict.system_2'),
  },
  // 产线状态 1 待激活, 2 已激活
  productionLineStatus: {
    0: i18n.t('dict.productionLineStatus_0'),
    1: i18n.t('dict.productionLineStatus_1'),
  },
  // 是否应用到target列 0 不应用到target列，1 应用到target列
  produceMtApply: {
    0: i18n.t('dict.produceMtApply_0'),
    1: i18n.t('dict.produceMtApply_1'),
  },
  // 客户实体表-是否确认 0-未确认 1-已确认
  customerStatus: {
    0: i18n.t('dict.customer_status_0'),
    1: i18n.t('dict.customer_status_1'),
  },
  // 版面尺寸
  pageFormat: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6'],
  // 版面方向
  pageLandscape: {
    false: i18n.t('pdf.landscape_false'),
    true: i18n.t('pdf.landscape_true'),
  },
}
