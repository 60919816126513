<template>
  <div class="NeoBigTitle" :class="className" :style="styles">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'NeoBigTitle',
  props: {
    text: {
      type: String,
      default: '这是一个大标题',
    },
    className: {
      type: String,
      default: '',
    },
    styles: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
.NeoBigTitle {
  height: 36px;
  font-size: @font_size_6;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 36px;
  color: @text_color_1;
  margin-bottom: 20px;
}
</style>
