<!--
 * @Author: gaojingran
 * @Date: 2021-03-20 09:54:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-31 10:22:53
 * @Description: svg Icon
-->
<style lang="less" scoped>
.icon {
  vertical-align: -0.1em;
  fill: currentColor;
  overflow: hidden;
  width: 1em;
  height: 1em;
}
</style>

<template>
  <svg class="icon" :style="{ fontSize: `${size}px` }" aria-hidden="true">
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'NeoIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 16,
    },
  },
}
</script>
