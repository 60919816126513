/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:14:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-07 15:27:31
 * @Description: 国际化
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import storageKey from '@/configs/storageKey'
// import Cookies from 'js-cookie'

import zhCN from './zh-CN'
import enUS from './en-US'

Vue.use(VueI18n)

const i18n = new VueI18n({
  // locale: Cookies.get(storageKey.LANGUAGE) || 'zh-CN',
  locale: 'zh-CN',
  messages: {
    'zh-CN': {
      _lang: '简体中文',
      ...zhCN,
    },
    'en-US': {
      _lang: 'English',
      ...enUS,
    },
  },
})

export default i18n
