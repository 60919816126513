/*
 * @Author: gaojingran
 * @Date: 2021-03-19 14:32:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-20 10:18:04
 * @Description: store
 */

import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

/**
 * 🏋️🌰🌰🌰🌰🏋️
 * https://vuex.vuejs.org/zh/guide/modules.html
 * namespace name 为 modules key 
 *  ...mapState('namespace name', {
        a: state => state.a,
        b: state => state.b
      })
 * mapMutations mapActions
    ...mapActions('namespace name', {
      foo: xxxfoo,  // -> this.xxxfoo()
    })
 */

export default new Vuex.Store({
  modules: {
    app,
  },
})
