/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:25:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-23 15:34:15
 * @Description: 系统权限map
 */

export default {
  // 创建订单
  order_newOrder_create: 'order:newOrder:create',
  // 首页 下单方模块
  home_customerView: 'home:customerView',
  // 首页 供应商模块
  home_providerView: 'home:providerView',
  // 草稿箱查看
  order_draftBox_view: 'order:draftBox:view',
  // 订单详情 -> 交付经理，下单经理，neocode
  order_orderInfo_manager: 'order:orderInfo:manager',
  order_orderInfo_managerVendor: 'order:orderInfo:managerVendor',
  order_orderInfo_neoCode: 'order:orderInfo:neoCode',

  // 订单详情 -> 终止订单，取消订单，创建报价，查看报价单，查看评价，评价，下载终稿, 上传终稿
  order_orderInfo_stopOrder: 'order:orderInfo:stopOrder',
  order_orderInfo_cancelOrder: 'order:orderInfo:cancelOrder',
  order_orderInfo_createQuote: 'order:orderInfo:createQuote',
  order_orderInfo_checkQuote: 'order:orderInfo:checkQuote',
  order_orderInfo_checkRate: 'order:orderInfo:checkRate',
  order_orderInfo_rate: 'order:orderInfo:rate',
  order_orderInfo_downloadFinish: 'order:orderInfo:downloadFinish',
  order_orderInfo_uploadFinish: 'order:orderInfo:uploadFinish',

  // 报价单 -> 批准报价  拒绝报价   新增报价项   新增价格
  order_orderInfo_quoteApprove: 'order:orderInfo:quoteApprove',
  order_orderInfo_quoteRefuse: 'order:orderInfo:quoteRefuse',
  order_orderInfo_addQuoteOption: 'order:orderInfo:addQuoteOption',
  order_orderInfo_addPrice: 'order:orderInfo:addPrice',

  // 订单列表
  order_orderList_customer: 'order:orderList:customer',
  order_orderList_provider: 'order:orderList:provider',
  // 下单经理字段
  order_orderList_presentManager: 'project:list:presentManager',

  //结算
  payment_customer: 'payment:customer',
  payment_provider: 'payment:provider',
  payment_customer_admin: 'payment:customer:admin',

  //客户管理
  customer_new_company: 'customer:newCompany',
  // 新客户管理
  customer_confirmbtn: 'customerConfig:menu:confirmBaseInfo',

  //客户详情
  companyInfo_update: 'agency:companyInfo:update',
  companyInfo_new_productionLine: 'agency:companyInfo:newProductionLine',
  companyInfo_new_price: 'agency:companyInfo:newPrice',
  companyInfo_new_manager: 'agency:companyInfo:newManager',
  companyInfo_import_price: 'agency:companyInfo:importPrice',
  companyInfo_import_manager: 'agency:companyInfo:importManager',

  //修改资料
  user_userInfo_customer: 'user:userInfo:customer',
  user_userInfo_provider: 'user:userInfo:provider',
}
