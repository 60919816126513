/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:07:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-29 11:24:29
 * @Description: app 基础配置
 */

import api from './api'
import storageKey from './storageKey'
import permissions from './permissions'
import localeDict from './localDict'
import validate from './validate'

const name = 'GientCloud'

const globalConfig = {
  isDev: process.env.NODE_ENV === 'development',
  // 不展示在Tab上的页面name
  ignoreNav: ['login', '404', 'styleGuide', 'pdfQuotation', 'pdfPayment'],
  // 请求地址
  api,
  // ajax 超时时间
  ajaxTimeout: 120 * 1000,
  // 系统权限
  permissions,
  // 持久化 Key
  storageKey: storageKey(name),
  // 前端字典表
  localeDict,
  // 表单校验
  validate,
  // 上传Accept校验
  acceptMap: {
    orderSourceFile: '.zip, .rar, .pptx, .txt, .xlsx, .sdlxliff, .docx',
    orderAttachmentFile: '.zip, .rar, .pptx, .txt, .xlsx, .sdlxliff, .docx',
  },
  // 字数报告匹配区间
  wordReportRangeColumn: [
    { produceVersion: 1, range: ['PM/ICE', '100%', 'Repetition', '95%-99%', '85%-94%', '75%-84%', 'MT', '0-74%'] },
    { produceVersion: 2, range: ['PM/ICE', '100%', 'Repetition', '95%-99%', '85%-94%', '75%-84%', 'MT', '0-74%'] },
    { produceVersion: 0, range: ['PM/ICE', '100%', 'Repetition', '95%-99%', '85%-94%', '75%-84%', 'MT', '0-74%'] },
    // {
    //   produceVersion: 2,
    //   range: ['PM', 'ICE', '100%', 'Repetition', '95%-99%', '85%-94%', '75%-84%', 'MT', '50%-74%', '0-49%'],
    // },
    // {
    //   produceVersion: 0,
    //   range: ['PM', 'ICE', '100%', 'Repetition', '95%-99%', '85%-94%', '75%-84%', 'MT', '50%-74%', '0-49%'],
    // },
  ],
  // 系统类型
  systemType: {
    neotrans: 1,
    order: 2,
  },
  // 用户类型
  userType: {
    sup: 1,
    resource: 2,
    customer: 3,
  },
  // 文档类型的value ui-软件界面词类 doc-文档类
  documentValue: {
    ui: '1160061146967879692',
    doc: '1160061850967879718',
  },
}

window.$g = globalConfig
