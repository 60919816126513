/*
 * @Author: gaojingran
 * @Date: 2021-03-19 14:32:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-16 16:27:09
 * @Description: router config
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import axios from '@/utils/request'
import store from '@/store'
import $g from '@/utils/global_variable.js'
Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

// 添加数组方法
Array.prototype.excludes = function (target) {
  return this.every((item) => item !== target)
}

/**
 * 判断当前路由是否为页面路由（页面路由无需校验信息）
 * @param {*} route 当前路由
 * @param {*} pageRoutes 页面路由
 */
function fnCurrentRouteIsPageRoute(route, pageRoutes = []) {
  let temp = []
  for (let i = 0; i < pageRoutes.length; i++) {
    if (route.path === pageRoutes[i].path) {
      return true
    }
    if (pageRoutes[i].children && pageRoutes[i].children.length >= 1) {
      temp = temp.concat(pageRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteIsPageRoute(route, temp) : false
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 */
function fnAddDynamicMenuRoutes(menuList = []) {
  const temp = []
  console.log('hgffhf', menuList)
  for (let i = 0; i < menuList.length; i++) {
    const menu = menuList[i]
    // 临时手动重置
    const isRedirect = (url) => {
      const configs = ['coConfig', 'customerConfig']
      if (configs.includes(url)) {
        const start = menu.children[0].url.split('/')[0]
        if (configs.excludes(start)) {
          return `/${start}/${url}/home`
        }
        return `/${url}/home`
      } else {
        return null
      }
    }

    const route = {
      path: `/${menu.url}`,
      component: null,
      name: menu.url?.replace(/\//g, '_'),
      redirect: isRedirect(menu.url),
      meta: {
        id: menu.id,
        isNav: menu.isNav,
        title: menu.name,
      },
    }

    if (menu.redirect) {
      route.redirect = {
        name: menu.redirect.replace(/\//g, '_'),
      }
    }
    route.component = () => import(`@/views/modules/${menu.url}`)
    if (menu.children && menu.children.length > 0) {
      route.children = fnAddDynamicMenuRoutes(menu.children)
    }
    temp.push(route)
  }
  return temp
}

// 独立页面(脱离 Layout)
const pageRoutes = [
  // 404
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/pages/404'),
  },
  // 导出报价单
  {
    path: '/pdf-quotation',
    name: 'pdfQuotation',
    component: () => import('@/views/pages/pdf-quotation'),
  },
  // 导出结算单
  {
    path: '/pdf-payment',
    name: 'pdfPayment',
    component: () => import('@/views/pages/pdf-payment'),
  },
  // 导出结算单-石头定制
  {
    path: '/Stone/pdf-payment',
    name: 'stonePdfPayment',
    component: () => import('@/views/pages/Stone/pdf-payment'),
  },
  // 导出报价单-石头定制
  {
    path: '/Kingsoft/pdf-quotation',
    name: 'kingsoftPdfQuotation',
    component: () => import('@/views/pages/Kingsoft/pdf-quotation'),
  },
  // login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/login'),
  },
  {
    path: '/coLogin',
    name: 'coLogin',
    component: () => import('@/views/pages/co-login'),
  },
].concat(
  window.$g.isDev
    ? [
        {
          path: '/style-guide',
          name: 'styleGuide',
          component: () => import('@/views/pages/style-guide'),
        },
      ]
    : []
)

// 模块路由(基于 Layout)
const moduleRoutes = {
  path: '/',
  name: 'main',
  children: [],
  component: () => import('@/views/layout'),
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }), //路由跳转后，滚动条回到顶部
  base: '/', // 路由的基路径，当用户输错路径默认跳转到基路径下
  routes: pageRoutes,
})

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 已添加或者当前路由为页面路由, 可直接访问
  if (window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] || fnCurrentRouteIsPageRoute(to, pageRoutes)) {
    return next()
  }
  // 加载进度条插件
  NProgress.start()
  // 获取字典 nav 权限 用户信息 语言列表
  Promise.all([
    axios.get(window.$g.api.dict_no_method),
    axios.get(window.$g.api.nav_no_method, { params: { system: 2 } }),
    axios.get(window.$g.api.permissions_no_method, {
      params: { system: 2 },
    }),
    axios.get(window.$g.api.userInfo_no_method),
    axios.get(window.$g.api.sysLangInfoList_no_method),
    axios.get(window.$g.api.dictTreeAll_no_method),
  ])
    .then(([dict, nav, permission, userInfo, langList, treeAll]) => {
      if (userInfo.type === 3 && !!userInfo.agencyId) {
        // 获取企业基本信息
        Promise.all([axios.get(window.$g.api.getCusAgencyInfo_no_method + userInfo.agencyId)]).then(
          ([cusAgencyInfo]) => {
            // vuex
            store.commit('app/update', { cusAgencyInfo: cusAgencyInfo })
          }
        )
      }
      NProgress.done()

      const discountRate = dict.find((v) => v.dictType === 'TRANS_2_ORDER_RATIO')?.dataList
      const customizedCustomerList = dict.find((v) => v.dictType === 'CUSTOMIZED_CUSTOMER_LIST')?.dataList

      async function go() {
        // 解决测试电脑因为延迟，导致的路由没有及时更新问题
        const $nav = await $g.modifyRoute({
          nav,
          customizedCustomerList,
          cusAgencyId: userInfo.cusAgencyId,
        })

        console.log(`$nav`, $nav)
        const route = fnAddDynamicMenuRoutes($nav)
        // 行业领域
        const industry = treeAll.find((v) => v.dictValue === '0')?.children || []
        // vuex
        store.commit('app/update', {
          userInfo: userInfo,
          dictList: dict,
          discountRate,
          permissions: permission,
          menuList: route,
          langList: langList,
          industryList: industry.map((v) => {
            const shake = (arr) => {
              return arr.map((c) => ({
                ...c,
                children: c.children.length ? shake(c.children) : undefined,
              }))
            }

            return {
              ...v,
              children: shake(v.children),
            }
          }),
        })

        // 赋值全局变量
        $g.setData({ userInfo, customizedCustomerList })
        console.error(`route`, route)
        // 添加路由
        router.addRoutes([
          {
            ...moduleRoutes,
            children: route, //添加菜单路由
            redirect: { name: route.length ? route[0].name : '404' },
          },
          { path: '*', redirect: { name: '404' } },
        ])

        window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = true
        next({ ...to, replace: true })
      }

      go()
    })
    .catch((err) => {
      console.error(err)
      NProgress.done()
      NProgress.remove()
      next({ name: 'login' })
    })
})

export default router
