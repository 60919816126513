<!--
 * @Author: gaojingran
 * @Date: 2021-04-08 09:46:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-28 09:46:33
 * @Description: 抽屉layout
-->
<style lang="less" scoped>
@footerHeight: 52px;
.neo-drawer {
  height: 100%;
  position: relative;
  .scroll-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    z-index: 1;
    &.with-footer {
      bottom: @footerHeight;
    }
  }
  .neo-drawer-footer {
    height: @footerHeight;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    border-top: 1px solid #e8e8e8;
  }
}
</style>

<template>
  <div class="neo-drawer">
    <NeoScroll v-if="!useOriginalScroll" class="scroll-area" :class="showFooter ? 'with-footer' : ''">
      <slot />
    </NeoScroll>
    <template v-else>
      <div style="padding: 20px">
        <slot />
      </div>
    </template>
    <div class="neo-drawer-footer" v-if="showFooter">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NeoDrawerLayout',
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    // 使用原生滚动条
    useOriginalScroll: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
