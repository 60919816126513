<!--
 * @Author: gaojingran
 * @Date: 2021-04-07 17:01:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-26 16:29:23
 * @Description: page layout
-->
<style lang="less" scoped>
@footer-height: 64px;

.neo-page {
  margin: 20px;
  background-color: #fff;
  min-height: calc(100% - 40px);
  // overflow-x: hidden;
  &.with-tab {
    min-height: calc(100% - @nav-tab-height - 40px);
  }
  &.with-footer {
    margin-bottom: 20px + @footer-height;
    min-height: calc(100% - @footer-height - 40px);
  }
  &.with-footer.with-tab {
    margin-bottom: 20px + @footer-height;
    min-height: calc(100% - @nav-tab-height - @footer-height - 40px);
  }
  .neo-page-footer {
    position: fixed;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: @sider-width;
    height: @footer-height;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(200, 213, 234, 0.3);
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}
</style>

<template>
  <div
    class="neo-page"
    :class="{
      'with-tab': reactiveLayoutWithTab,
      'with-footer': showFooter,
    }"
  >
    <slot />
    <div class="neo-page-footer" v-if="showFooter">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NeoPageLayout',
  inject: ['layoutWithTab'],
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    reactiveLayoutWithTab() {
      return this.layoutWithTab()
    },
  },
}
</script>
