/* eslint-disable no-useless-escape */
/*
 * @Author: gaojingran
 * @Date: 2021-03-29 14:18:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-10 13:42:42
 * @Description: 全局filter
 */

import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'
import is from '@/utils/is'
import i18n from '@/i18n'
import { htmlEncode, useAccelerateUrl } from './utils'
// null || undefined 展示 '---'
Vue.filter('defined', function (data) {
  if (!is.Defined(data)) {
    return '---'
  } else {
    return data
  }
})
// 字符串里的<xxx> 替换 <tag>
Vue.filter('tag', function (str) {
  const reg = /(<[^<>]*>)+/g

  if (reg.test(str)) {
    str = str.replace(/(<[^<>]*>)+/g, '<tag>')
  }
  str = htmlEncode(str ?? '')

  return str
})

// 日期
Vue.filter('dateFormat', function (date, format = i18n.t('dateFormat.a')) {
  if (!is.Defined(date)) {
    return '---'
  } else {
    return moment(date).format(format)
  }
})

// 最大保留2位小数
Vue.filter('countFormat', function (count) {
  if (!is.Defined(count)) {
    return '---'
  } else {
    return numeral(count).format('0.[00]')
  }
})
// 鲸译对应的阶段
Vue.filter('currentStage', function (stage) {
  console.log(`stage`, stage)
  const deleteNum = (str) => {
    const _str = str
    return _str.replace(/[0-9]+/g, '')
  }
  const index = ~~stage.replace(/[^0-9]/gi, '') + 1
  const str = deleteNum(stage)
  const mappingFunc = (index, str) => {
    const mapping = {
      'Quality Assurance': 'QualityAssurance',
      'Feedback Implementation': 'FeedbackImplementation',
    }
    console.log({ index, str })
    const _str = mapping[str] || str
    return i18n.t('orderInfo.jinyiSteps', { index }) + '：' + i18n.t(`orderInfo.${_str}`)
  }
  return mappingFunc(index, str)
})

Vue.filter('transUploadStatusText', function (v) {
  return [
    '解析中',
    '已上传',
    '解析失败',
    'tmx文件内容为空！',
    '该tmx文件的语言对与当前订单不相符，请重新上传！',
    'zip中没有tmx文件！',
    'zip包中文件格式有误，请重新上传！',
  ][v - 1]
})

// 转换为 oss 传输加速的 url
Vue.filter('accelerateUrl', function (url) {
  return useAccelerateUrl(url)
})
