/*
 * @Author: gaojingran
 * @Date: 2021-03-19 14:32:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-27 10:21:34
 * @Description: main config
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './configs'
import Vue from 'vue'
import Antd from 'ant-design-vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import NeoIcon from './components/common/NeoIcon'
import NeoTitle from './components/common/NeoTitle'
import NeoBigTitle from './components/common/NeoBigTitle'
import NeoPageLayout from './components/common/NeoPageLayout'
import NeoDrawerLayout from './components/common/NeoDrawerLayout'
import is from './utils/is'
import { requireAll, hasPermission } from './utils/utils'
import './utils/filter'
import './assets/styles/antdTheme.less'
import './assets/styles/index.less'
import $g from './utils/global_variable.js'
import withAsync from '@/mixins/withAsync'

Vue.config.productionTip = false
// 导入 svg 目录下所有文件
requireAll(require.context('./assets/svg', true, /\.svg$/))
// antd component
Vue.use(Antd)
// 全局混入请求方法
Vue.mixin(withAsync)
// 类型校验
Vue.prototype.$is = is
// 系统权限
Vue.prototype.$role = hasPermission
// 发布订阅
Vue.prototype.$bus = new Vue()
// 配置挂载在vue上的全局方法和变量，可使用
Vue.prototype.$g = $g
//仅仅供开发者查看，不作为数据使用
window.vue_$g = $g
// 通用组件
Vue.component('NeoIcon', NeoIcon)
Vue.component('NeoTitle', NeoTitle)
Vue.component('NeoBigTitle', NeoBigTitle)
Vue.component('NeoPageLayout', NeoPageLayout)
Vue.component('NeoDrawerLayout', NeoDrawerLayout)
Vue.component('NeoScroll', vueCustomScrollbar)

const vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

export default vm
