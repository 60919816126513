<!--
 * @Author: gaojingran
 * @Date: 2021-03-19 14:32:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-27 12:32:59
 * @Description: app
-->

<template>
  <a-config-provider :locale="antLocal" :auto-insert-space-in-button="false">
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'

export default {
  name: 'App',
  computed: {
    antLocal() {
      return this.$i18n.locale === 'zh-CN' ? zhCN : enUS
    },
  },
}
</script>
