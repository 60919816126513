/*
 * @Author: gaojingran
 * @Date: 2021-04-01 13:06:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-25 10:26:23
 * @Description: axios
 */

import axios from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'
import isPlainObject from 'lodash/isPlainObject'
// eslint-disable-next-line no-unused-vars
import { clearLoginInfo } from '@/utils/utils'
// eslint-disable-next-line no-unused-vars
import $g from '@/utils/global_variable.js'

const { ajaxTimeout, storageKey } = window.$g

axios.defaults.baseURL = window.SITE_CONFIG['apiURL']
axios.defaults.timeout = ajaxTimeout
axios.defaults.withCredentials = true

/**
 * 请求拦截
 */
axios.interceptors.request.use(
  (config) => {
    // config.headers['Accept-Language'] = Cookies.get(storageKey.LANGUAGE) || 'zh-CN'
    config.headers['Accept-Language'] = 'zh-CN'
    config.headers['token'] = Cookies.get(storageKey.TOKEN) || ''
    config.headers['tenant_code'] = Cookies.get(storageKey.TENANTCODE) || ''
    config.headers['gienttrans_fresh_features'] = Cookies.get(storageKey.CANARY) || ''
    // 默认参数
    const defaults = {}
    // 防止缓存，GET请求默认带_t参数
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        ...{ _t: new Date().getTime() },
      }
    }
    if (isPlainObject(config.params)) {
      config.params = {
        ...defaults,
        ...config.params,
      }
    }
    if (isPlainObject(config.data)) {
      config.data = {
        ...defaults,
        ...config.data,
      }
      if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
        config.data = qs.stringify(config.data)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
axios.interceptors.response.use(
  (response) => {
    const { data, status, config } = response
    if (status === 200 && config.responseType === 'blob') {
      return Promise.resolve(data)
    }
    if (data.code === 0) {
      return Promise.resolve(data.data)
    } else if (data.code === 401) {
      // || data.code === 10001
      // token 失效
      clearLoginInfo()
      $g.returnLogin()
      return Promise.reject(false)
    } else {
      return Promise.reject({
        code: data.code,
        details: data.details,
        msg: data.msg,
      })
    }
  },
  (error) => {
    if (error.code === 'ECONNABORTED') {
      return Promise.reject({ code: 'timeout' })
    }
    if (error.message && error.code === 'canceled' && !error.code) {
      return Promise.reject({ code: 'canceled' })
    }
    console.error('Interceptors response error =>', error)
    return Promise.reject(error)
  }
)

export default axios
