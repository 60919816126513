/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:21:10
 * @LastEditors: Please set LastEditors
 * @Description: api接口map
 */

export default {
  // 基础接口
  login: 'post::/auth/login',
  logout: 'post::/auth/logout',
  auth_captcha_no_method: '/auth/captcha',
  auth_captchaByEmail: 'post::/auth/captchaByEmail',
  auth_resetPassword: 'post::/auth/password/resetPassword',

  userInfo: 'get::/sys/user/info',
  userInfo_no_method: '/sys/user/info',

  permissions: 'get::/sys/menu/permissions',
  permissions_no_method: '/sys/menu/permissions',

  dict: 'get::/sys/dict/type/all',
  dict_no_method: '/sys/dict/type/all',

  dictTree: 'get::/sys/dict/tree/all',
  dictTreeAll_no_method: '/sys/dict/tree/all',

  nav: 'get::/sys/menu/nav',
  nav_no_method: '/sys/menu/nav',
  ossCmdHtmlToPdf: 'post::/oss/cmd/htmlToPdf',
  // 语言列表
  sysLangInfoList: 'get::/sys/sysLangInfo/sysLangInfoI18nList',
  sysLangInfoList_no_method: '/sys/sysLangInfo/sysLangInfoI18nList',
  // ali oss
  aliOssStsToken: 'get::/oss/auth/getTempAccount',
  // 创建订单 -> 提交获取报价
  processPresent: 'post::/order/process/present',
  // 创建订单 -> 保存草稿箱
  processSave: 'post::/order/process/save',
  // 订单详情
  orderProcessInfo: 'get::/order/process/info/{id}',
  // 订单翻译进度
  orderNeoProduceProgress: 'get::/order/neo/produceProgress',
  // 订单进度
  orderProcessProgress: 'get::/order/process/progress/{id}',
  // 取消订单
  orderProcessWithdraw: 'post::/order/process/withdraw',
  // 终止订单
  orderProcessTerminate: 'post::/order/process/terminate',
  // 订单字数报告
  orderWordReportSync: 'post::/order/wordReport/sync',
  // 订单权重字数信息
  orderWordReportInfo: 'get::/order/wordReport/info/{id}',
  // 订单权重比率信息
  orderWordReportRatio: 'get::/order/process/discount/{id}',
  // 订单创建报价上传字数报告
  orderWordReportUpload_no_method: '/order/wordReport/upload',
  // 订单调整权重字数
  orderWordReportAdjust: 'post::/order/wordReport/adjust',
  // 报价单信息
  orderProcessQuoteInfo: 'get::/order/process/quoteInfo/{id}',
  // orderProcessQuoteInfoRockWell: 'get::/order/rockWell/process/info/{id}',
  // 新增报价项其他服务类型价格
  orderProcessOtherPrice: 'get::/order/process/otherPrice/{id}', //####
  // 报价单创建报价
  orderProcessQuote: 'post::/order/process/quote',
  // 批准报价
  orderProcessQuoteConfirm: 'post::/order/process/quoteConfirm',
  // 反馈修改
  orderProcessProduceFeedback: 'post::/order/process/produceFeedback',
  // 确认终稿
  orderProcessProduceConfirm: 'post::/order/process/produceConfirm',
  // 订单缩短的交付周期
  orderStatisticTimeSaving: 'post::/order/statistic/timeSaving',
  // 订单成本节约
  orderStatisticCostSaving: 'post::/order/statistic/costSaving',
  // 订单累计语料
  orderStatisticCorpus: 'post::/order/statistic/corpus',
  // 订单评价
  orderProcessProduceRemark: 'post::/order/process/produceRemark',
  // 订单评价信息
  orderProcessRemarkInfo: 'get::/order/process/remarkInfo/{id}',
  // 批量报价单信息
  orderProcessQuoteBatchInfo: 'get::/order/process/quoteBatchInfo/{ids}',
  // 产线列表下拉
  productLineList: 'get::/order/productLine/list',
  // 草稿箱
  orderDraftPage: 'get::/order/process/draftPage',
  // 删除: 订单列表 , 订单列表-草稿箱 , 订单信息表
  orderDel: 'post::/order/process/del',
  // 待办事项
  schedule: 'get::/order/statistic/todoPage',
  stoneTodoPage: 'get::/order/statistic/stoneTodoPage',
  // 获取客户信息列表
  cusAgencyList: 'get::/customer/agency/cusAgencyList',
  // 获取产线
  productionLineInfo: 'get::/order/productLine/page',
  // 根据产线id 获取产线详情
  productionLineDetails: 'get::/order/productLine/info/{id}',
  // 根据产线id 获取产线详情2（新版）
  productionLineDetails_2: 'get::/order/productLine/infoVersion2/{id}',
  // 产线详情-修改
  updateProductionLine: 'post::/order/productLine/updateProductLine',
  // 产线详情-修改2（新版）
  updateProductionLine_2: 'post::/order/productLine/updateProductLineVersion2',
  // 根据客户实体id获取企业信息列表
  getCusAgencyInfo: 'get::/customer/cusEntity/{id}',
  getCusAgencyInfo_no_method: '/customer/cusEntity/',

  // 根据客户实体id获取价格列表
  priceList: 'get::/customer/price/page',
  // 根据客户实体id获取服务价格
  getPriceById: 'get::/customer/price/info/{id}',
  // 根据客户实体id获取客户实体详细信息
  agencyInfo: 'get::/customer/agency/info/{id}',
  // 获取下单经理列表
  managerInfo: 'get::/customer/cusInfo/page',
  // 根据id获取客户员工
  getManagerById: 'get::/customer/cusInfo/info/{id}',
  // 新增公司-同步按钮获取实体信息
  agencyInfoFromSERP: 'get::/customer/agency/getAgencyFromSERP',
  // 新增公司-clientProjectList
  clientProjectList: 'get::/order/neo/clientProjectList',
  // 新增公司-jobTypeList
  jobTypeList: 'get::/order/neo/jobTypeList',
  // 新增公司-ratioList
  ratioList: 'get::/order/neo/ratioList',
  // 新增公司-mtEngineList
  mtEngineList: 'get::/order/neo/mtEngineList',
  // 新增公司-TM&Term组
  tmTermGroupList: 'get::/order/neo/tmTermGroupList',
  // 订单列表
  orderList: 'get::/order/process/page',
  // 获取高级交付经理列表
  getSpmList: 'get::/agency/user/spmList',
  // 获取交付经理列表
  getPmList: 'get::/agency/user/list',
  // 获取生产经理列表
  // getproduceManagerList: 'get::/agency/user/getPaList',
  getproduceManagerList: 'get::/order/neo/produceManagerList',
  // 更新产品经理
  changeSpm: 'post::/customer/agency/updateSupplierSpm',
  // 新增价格
  newPrice: 'post::/customer/price/add',
  // 删除价格
  deletePrice: 'post::/customer/price/del',
  // 导入价格
  importPrice: 'post::/customer/price/uploadCusPrice',
  // 编辑价格
  editPrice: 'post::/customer/price/update',
  // 下载价格文档
  downloadPrice: 'post::/customer/cusInfo/downloadTemplate',
  // 新增下单经理
  newManager: 'post::/customer/cusInfo/add',
  // 导入下单经理
  importManager: 'post::/customer/cusInfo/uploadEmployees',
  // 下载下单经理模板
  downloadManager: 'post::/customer/cusInfo/downloadTemplate',
  // 编辑下单经理
  editManager: 'post::/customer/cusInfo/update',
  // 设置离职和在职
  setStatus: 'post::/customer/cusInfo/updateStatus',
  // 修改密码
  password: 'post::/sys/user/password',
  // 获取客户用户信息
  customerInfo: 'get::/customer/cusEmployee/getUserDetailInfo',
  // 根据供应商id获取用户信息
  companyInfo: 'get::/customer/supEmployeeDetail/getUserDetailInfo',
  // 修改客户的用户信息
  updateCustomerInfo: 'post::/customer/cusEmployee/update',
  // 修改供应商的用户信息
  updateCompanyInfo: 'post::/customer/supEmployeeDetail/update',
  // 累计下单
  totalOrders: 'get::/order/statistic/presentCount',
  // 累计语料
  totalCorpus: 'get::/order/statistic/corpusCount',
  // 节约成本
  savingCost: 'get::/order/statistic/costSavingCount',
  // 缩短周期
  savingTime: 'get::/order/statistic/timeSavingRatio',
  // 订单结算分页
  orderSettlement: 'get::/order/settlement/page',
  // 结算单信息
  statementInfo: 'get::/order/settlement/info/{id}',
  // 批量结算单信息
  batchStatementInfo: 'get::/order/settlement/batchInfo/{ids}',
  // 批准结算(供应方)
  approveSettleSupply: 'post::/order/settlement/confirm',
  // 批准结算(下单方)
  approveSettleClient: 'post::/order/settlement/price',
  // 拒绝结算
  rejectSettle: 'post::/order/settlement/reject',
  // 新增产线
  newProductionLine: 'post::/order/productLine/add',
  // 新增产线2
  newProductionLine_2: 'post::/order/productLine/addVersion2',
  // 新增产线2-获取program列表
  programList: 'get::/order/productLineProgram/list',
  // 新增产线2-获取clientProject列表
  namePidList: 'get::/project/clientProject/namePidList',
  // 激活产线
  activeProductionLine: 'post::/order/productLine/activate',
  // 员工列表
  companyUser: 'get::/agency/user/page',
  // 设置员工在离职
  companyUserStatus: 'post::/agency/user/setStatus',
  // 设置员工角色
  companyUserRole: 'post::/agency/user/bindRole',
  // 开票接口
  invoice: 'post::/order/settlement/invoice',
  // 订单状态数量
  orderStatusCount: 'get::/order/process/orderStatusCount',
  // 待办事项的统计
  orderStatisticTodoCount: 'get::/order/statistic/todoCount',
  stoneTodoCount: 'get::/order/statistic/stoneTodoCount',

  /*报表统计模块*/

  // 订单金额统计
  orderMoneyCount: 'get::order/statistic/orderMoneyCount',
  // 翻译字数统计
  translationWordCount: 'get::order/statistic/translationWordCount',

  // 评价统计
  report_remark: 'get::/order/report/remark',
  // 评价统计概览
  report_remarkOverview: 'get::/order/report/remarkOverview',
  // 订单统计
  report_price: 'get::/order/report/price',
  // 产品线分布统计
  report_priceLine: 'get::/order/report/priceLine',
  // 语项统计
  report_pair: 'get::/order/report/pair',
  // 订单反馈历史
  feedbackHistory: 'get::/order/process/feedbackHistory/{id}',
  // 翻译记录
  translationHistory: 'get::/order/translationHistory/page',
  // 待办事项企业实体列表
  getAgencyListInfo: 'get::/customer/supEntity/getCusSupList',

  // 客户实体表-更新
  cusInfoUpdate: 'post::/customer/cusEntity/update',
  // 客户产品线表-保存
  saveCusProductLine: 'post::/customer/cusProductLine/save',
  // 客户产品线表-更新
  updateCusProductLine: 'post::/customer/cusProductLine/update',
  // 客户产品线表-列表
  cusProductLineList: 'get::/customer/cusProductLine/list',
  // 客户产品线表-删除
  deleteCusProductLine: 'post::/customer/cusProductLine/delete',
  // 客户资料表（服务类型、文档类型）-保存
  saveCusMaterial: 'post::/customer/cusMaterial/save',
  // 客户资料表（服务类型、文档类型）-更新
  updateCusMaterial: 'post::/customer/cusMaterial/update',
  // 客户资料表（服务类型、文档类型）-列表
  cusMaterialList: 'get::/customer/cusMaterial/list',
  // 客户资料表（服务类型、文档类型）-删除
  deleteCusMaterial: 'post::/customer/cusMaterial/delete',
  // 客户实体表-保存
  cusInfoSave: 'post::/customer/cusEntity/save',
  // 客户实体表-列表
  cusInfoList: 'get::/customer/cusEntity/page',
  // 客户实体表-校验实体名是否存在
  checkByCusName: 'get::/customer/cusEntity/checkByCusName',
  // 客户实体表-校验税号是否存在
  checkByTaxIdNumber: 'get::/customer/cusEntity/checkByTaxIdNumber',
  // 客户实体表-校验邮箱是否存在
  checkByEmail: 'get::/customer/cusEmployee/checkByEmail',
  // 客户实体表-客户详情
  getCusInfo: 'get::/customer/cusEntity/{id}',
  // 客户实体表-更新
  cusInfoUpdateCusInfo: 'post::/customer/cusEntity/updateCusInfo',
  // 修改资料-查询交付经理
  getUserDetailInfo: 'get::/customer/supEmployeeDetail/getUserDetailInfo',

  /* 
  客户端模块
   */
  // 供应商列表
  supEntityList: 'get::/customer/supEntity/list',
  // 添加供应商基本信息
  addUpdateSupEntity: 'post::/customer/supEntity/save',
  // 供应商基本信息
  supEntityInfo: 'get::/customer/supEntity/{id}',
  // 供应商基本信息
  // cusProductLineList: 'get::/customer/cusProductLine/list',
  // 修改供应商基本信息
  updateSupEntity: 'post::/customer/supEntity/update',
  // 客户禁用供应商校验
  updateInactiveCheck: 'get::/customer/supEntity/updateInactiveCheck',
  // 客户维护供应商启用禁用功能
  updateInactive: 'post::/customer/supEntity/updateInactive',
  // 客户给供应商发送确认邮件
  sendConfirmEmail: 'post::/customer/supEntity/sendConfirmEmail',
  // 添加供应商基本信息（保存折扣预设）
  saveSupDiscount: 'post::/customer/supDiscount/update',
  // 获取供应商列表
  getSupList: 'post::/customer/supEntity/getSupList',
  // 下单经理-分页
  cusEmployeeList: 'get::/customer/cusEmployee/page',
  // 下单经理-下拉列表
  cusEmployeeOptionList: 'get::/customer/cusEmployee/getEmployeeOptionList',
  // 下单经理-保存
  cusEmployeeSave: 'post::/customer/cusEmployee/save',
  // 下单经理-查询
  cusEmployeeQuery: 'get::/customer/cusEmployee/{id}',
  // 下单经理-修改
  cusEmployeeUpdate: 'post::/customer/cusEmployee/update',
  // 下单经理-导入
  cusEmployeeUpload: 'post::/customer/cusEmployee/upload',
  // 下单经理-离职/在职
  cusEmployeeUpdateStatus: 'post::/customer/cusEmployee/updateStatus',
  // 校验税号是否存在
  checkSupByTaxIdNumber: 'get::/customer/supEntity/checkByTaxIdNumber',
  // 校验实体名是否存在
  checkBySupName: 'get::/customer/supEntity/checkBySupName',
  // 获取下单时的语言对信息
  getOrderPairInfo: 'get::/customer/supPrice/getOrderPairInfo',

  /* 
  供应商模块
   */

  // 获取企业和供应商基本信息
  getCusAndSupEntityList: 'get::/customer/supEntity/getCusAndSupEntityList',
  // 获取供应商折扣区间
  supDiscountInfo: 'get::/customer/supDiscount/supDiscountInfo',
  // 修改供应商确认状态
  updateSupStatus: 'post::/customer/supEntity/updateSupStatus',

  // 供应商价格表-分页
  supPriceList: 'get::/customer/supPrice/page',
  // 供应商价格表-添加
  supPriceSave: 'post::/customer/supPrice/save',
  // 供应商价格表-修改
  supPriceUpdate: 'post::/customer/supPrice/update',
  // 供应商价格表-删除
  supPriceDelete: 'post::/customer/supPrice/delete',

  // 交付经理tab列表
  supEmployeeDetail: 'get::/customer/supEmployeeDetail/page',
  // 校验交付经理邮箱
  checkDeliveryEmail: 'get::/customer/cusEmployee/checkEmail',
  // 添加交付经理
  saveSupEmployeeDetail: 'post::/customer/supEmployeeDetail/saveSupEmployeeDetail',
  // 更新交付经理
  updateDeliverManager: 'post::/customer/supEmployeeDetail/update',
  // 交付经理tab列表编辑-查询交付经理
  supEmployeeDetailId: 'get::/customer/supEmployeeDetail/{id}',
  // 导入交付经理
  importDeliverManager: 'post::/customer/supEmployeeDetail/batchSaveSupEmployeeDetail',
  // 供应商产品线表-列表
  fetchProductionLineList: 'get::/customer/cusProductLine/productLineList',
  // 供应商产品线表-交付经理列表
  getUserDetailList: 'get::/customer/supEmployeeDetail/getUserDetailList',
  // 供应商产品线表-获取program列表
  fetchProgramList: 'get::/order/productLineConfigProgram/list',
  // 供应商产品线表-产品线生产配置信息
  fetchProductLineInfo: 'get::/order/productLineConfig/info/{id}',
  // 供应商产品线表-产品线生产配置信息
  fetchProductLineInfo2: 'get::/order/rockWell/productLineConfig/info/{id}',
  // 供应商产品线表-保存产品线生产配置
  productLineConfigSave: 'post::/order/productLineConfig/save',
  productLineConfigSaveRockWell: 'post::/order/rockWell/productLineConfig/save',
  ccEmailsTemp: 'get::/order/rockWell/productLineConfig/ccEmailsTemp',
  // 供应商产品线表-给产线配置交付经理
  saveOrUpdateProjectManager: 'post::/order/productLineConfig/saveOrUpdate',
  // 供应商可以直接上传终稿
  submitFinalDraft: 'post::/order/process/submitFinalDraft',

  // 供应商上传翻译记录
  saveTranslationHistory: 'post::order/translationHistory/saveTranslationHistory',
  // 查询是否已经添加过翻译记录
  checkIsExistTranslationHistory: 'get::order/translationHistory/checkIsExistTranslationHistory',
  // 查询上传的翻译记录
  translationHistoryList: 'get::order/translationHistory/translationHistoryPage',
  // 查询文件解析状态
  getOrderParseStatus: 'get::order/process/getOrderParseStatus',
  // 下单缓存信息
  orderTemp: 'get::/order/process/orderTemp',

  /*
  rockWell定制化接口
  */
  submitOrder: 'post::/order/rockWell/process/present',
  saveDraftbox: 'post::/order/rockWell/process/save',
  orderProcessInfo2: 'get::/order/rockWell/process/info/{id}',
  submitFinalDraft2: 'post::/order/rockWell/process/submitFinalDraft',
  getRockWellQuoteInfo: 'get::/order/rockWell/quoteInfo/{id}',
  // getRockWellQuoteInfo: 'get::/order/rockWell/process/quoteInfo/{id}',
  exportExcelRockWell_no_method: '/order/rockWell/quoteInfo/export/',
  exportBatchExcelRockWell: 'get::/order/rockWell/quoteInfo/batchExport',
  exportBatchExcelRockWell_no_method: '/order/rockWell/quoteInfo/batchExport/',
  supDiscountPairInfoMTPE: 'get::/customer/supDiscountPair/supDiscountPairInfo',
  /*第一步*/
  // 获取初始ratio
  getInitWwcRatioRockWell: 'get::/order/rockWell/wordReport/getInitWwcRatio/{id}',
  // 保存或更新Wwc
  saveOrUpdateWordReportRockWell: 'post::/order/rockWell/wordReport/saveOrUpdate',

  /*第二步*/
  // 调整权重字数
  adjustWordReportRockWell: 'post::/order/rockWell/wordReport/adjust',
  adjustWordReportRockWell2: 'post::/order/rockWell/process/workdays',
  // 获取订单权重字数信息
  getWordReportInfoRockWell: 'get::/order/rockWell/wordReport/info/{id}',
  // 同步字数报告
  syncWordReportRockWell: 'post::/order/rockWell/wordReport/sync',
  // 上传字数报告
  orderWordReportUploadRockWell_no_method: '/order/rockWell/wordReport/upload',
  // Rockwell订单列表
  orderListRockwell: 'get::/order/rockWell/process/page',
  // Excel有反馈的订单导出
  feedbackExportRockwell: 'get::/order/rockWell/process/feedbackExport',
  feedbackExportRockwell_no_method: '/order/rockWell/process/feedbackExport',
  sendFinalDraftDownloadEmail: 'post::/order/process/sendFinalDraftDownloadEmail',
  getPriceListBySupId: 'get::/customer/supPrice/getPriceListBySupId',

  /*
  石头定制化接口
  */
  // 保存订单通知
  saveNoticeStone: 'post::/order/stone/notice/batchSave',
  getNoticeStone: 'get::/order/stone/notice/noticeList',
  submitFinalDraftStone: 'post::/order/stone/process/submitFinalDraft',

  stoneExamineRealPrice: 'post::/order/stone/process/examineRealPrice', // 审批实际订单金额
  stoneProcessInfo: 'get::/order/stone/process/info/{id}', // 信息 订单信息表
  stoneModifyRealPrice: 'post::/order/stone/process/modifyRealPrice', // 修改实际订单金额
  stoneProcessPresent: 'post::/order/stone/process/present', // 提交
  stoneQuote: 'post::/order/stone/process/quote', // 报价 --> 新增报价说明
  stoneRejectQuotation: 'post::/order/stone/process/rejectQuotation', // 退回重新报价
  stoneQuoteInfo: 'get::/order/stone/process/quoteInfo/{id}', // 报价单信息
  stoneWorkdays: 'post::/order/stone/process/workdays', // 填写预估工作日
  stoneSettlement: 'get::/order/stone/settlement/info/{id}', // 信息 订单结算表
  stoneSettlementBatchInfo: 'get::/order/stone/settlement/batchInfo/{ids}', // 批量订单结算
  stoneOrderSettlement: 'get::/order/stone/settlement/page', // 订单结算分页

  stoneUploadQuoteFile: 'post::/order/stone/process/uploadQuoteFile', //上传pdf
  stoneGetQuoteFile: 'get::/order/stone/process/getQuoteFile/{id}', //下载pdf

  stoneCustomerList: 'get::/customer/cusMaterial/list', // 客户资料表 -列表
  stoneCustomerSave: 'post::/customer/cusMaterial/save', // 客户资料表 -保存
  stoneCustomerUpdate: 'post::/customer/cusMaterial/update', //客户资料表 -更新
  stonecustomerInfo: 'get::/customer/cusMaterial/{id}', //客户资料表 -信息

  // 【石头】订单列表
  stoneListPage: 'get::/order/stone/process/page',
  // 历史终稿记录
  finalDraftHistory: 'get::/order/rockWell/process/finalDraftHistory/{orderId}',
  finalDraftHistoryAll: 'get::/order/process/finalDraftHistory/{orderId}',
  // 产线列表
  cusProductLineEntry: 'get::/customer/cusProductLine/cusProductLineList',
  translationHistoryLanguagePair: 'get::order/translationHistory/translationHistoryLanguagePair',
  // 修改订单
  changeOrderContent: 'post::/order/process/changeOrderContent',
  rockWellChangeOrderContent: 'post::/order/rockWell/process/changeOrderContent',
  stoneChangeOrderContent: 'post::/order/stone/process/changeOrderContent',
  // 获取最新修改记录
  lastRecordList: 'get::/order/orderModifyRecord/lastRecordList/{orderId}',

  // 在线咨询
  stoneCreateQuestion: 'post::/order/stone/orderQuestion/createQuestion', //创建问题
  questionList: 'get::/order/orderQuestion/questionList', //问题列表
  stoneCloseQuestion: 'post::/order/stone/orderQuestion/closeQuestion/{questionId}', //关闭问题
  stoneCreateQuestionReply: 'post::/order/stone/questionReply/createQuestionReply', //问题回复

  createQuestion: 'post::/order/orderQuestion/createQuestion', // 通用创建问题
  closeQuestion: 'post::/order/orderQuestion/closeQuestion/{questionId}', // 通用关闭问题
  createQuestionReply: 'post::/order/questionReply/createQuestionReply', // 通用问题答复

  unCloseQuestion: 'post::/order/orderQuestion/unCloseQuestion', //获取该订单下未关闭的问题
  stoneSettlementConfirm: 'post::/order/stone/settlement/confirm', //批准结算
  stoneSettlementPrice: 'post::/order/stone/settlement/price', //价格结算

  // 金山版
  kingsoftOrderparseWordAmountUpload_no_method: '/order/kingsoft/process/parseWordAmount', //上传字数文件并解析文件字数
  kingsoftProcessQuoteInfo: 'get::/order/kingsoft/process/quoteInfo/{id}', //报价单信息
  kingsoftProcessQuote: 'post::/order/kingsoft/process/quote', // 报价
  kingsoftExportBatchInfoExcel: 'get::/order/kingsoft/settlement/exportBatchInfoExcel', // 导出Excel批量结算单
}
