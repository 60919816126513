/*
 * @Author: gaojingran
 * @Date: 2021-03-20 10:04:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-16 14:34:56
 * @Description: 全局store
 */
import is from '../utils/is'

export default {
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {},
    // 系统字典
    dictList: [],
    // 语言列表
    langList: [],
    // 行业领域
    industryList: [],
    // 用户权限
    permissions: [],
    // 菜单
    menuList: [],
    // 企业基本信息
    cusAgencyInfo: {},
    // 客户端识别码
    bizTenantCode: '',
  },
  getters: {
    /**
     * 获取字典表数据
     * 🌰 this.$store.getters['app/getDictByType'](type);
     * */
    getDictByType: (state) => (type) => {
      return state.dictList.find((item) => item.dictType === type)?.dataList || []
    },
    /**
     * 获取字典表对应value的label
     * 🌰 this.$store.getters['app/getDictLabel'](dictType, dictValue);
     * */
    getDictLabel: (state) => (dictType, dictValue) => {
      const dict = state.dictList.find((item) => item.dictType === dictType)?.dataList || []
      return dict.find((v) => v.dictValue === `${dictValue}`)?.dictLabel
    },
    /**
     * 获取语言列表对应name
     * 🌰 this.$store.getters['app/getLangNameByCode'](code)
     * 当code逗号隔开就返回逗号隔开的name
     * */
    getLangNameByCode: (state) => (code) => {
      if (!is.Defined(code)) return undefined
      const codeArray = code.split(',')
      return codeArray
        .map((c) => state.langList.find((v) => v.code === c)?.name)
        .filter((v) => v)
        .join(',')
    },
    /**
     * 获取行业领域对应name
     * 🌰 this.$store.getters['app/getIndustryName'](id)
     * */
    getIndustryName: (state) => (id) => {
      if (!is.Defined(id)) return undefined
      let result = []

      const getTreeItem = (data) => {
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          if (item.dictValue === id) {
            return result.push(item.dictLabel)
          }
          if (new RegExp('^' + item.dictValue).test(id) && item.children) {
            result.push(item.dictLabel)
            getTreeItem(item.children)
          }
        }
      }

      getTreeItem(state.industryList)
      return result
    },
  },
  mutations: {
    update(state, n) {
      for (let key in n) {
        state[key] = n[key]
      }
    },
    updateTenantCode(state, code) {
      state.bizTenantCode = code
      console.log(`state.bizTenantCode`, state.bizTenantCode)
    },
  },
  actions: {},
}
