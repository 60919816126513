/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:21:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-06 13:40:17
 * @Description: storage key map
 */

export default (name) => {
  return {
    // cookie
    TOKEN: 'token',
    TENANTCODE: 'tenant_code',
    LANGUAGE: 'language',
    CANARY: 'gienttrans_fresh_features',
    // web storage key
    ACCOUNT: `${name}_account`,
    NAVTAB: `${name}_nav_tab`,
  }
}
