<!--
 * @Author: gaojingran
 * @Date: 2021-03-25 16:18:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-17 10:00:15
 * @Description: 标题栏
-->

<style lang="less" scoped>
.neo-title {
  display: flex;
  align-items: center;
  .line {
    width: 2px;
    height: 10px;
    background-color: @primary-color;
    margin-right: 10px;
  }
  .title {
    font-size: @font_size_2;
    color: @text_color_1;
    font-weight: bold;
  }
}
.neo-title.pure-bw {
  .line {
    background-color: #000000;
  }
  .title {
    color: #000000;
  }
}
</style>

<template>
  <span class="neo-title" :class="className" :style="styles">
    <span class="line" />
    <span class="title">{{ text }}</span>
  </span>
</template>

<script>
export default {
  name: 'NeoTitle',
  props: {
    text: {
      type: String,
      default: '这是一个Title',
    },
    className: {
      type: String,
      default: '',
    },
    styles: {
      type: String,
      default: '',
    },
  },
}
</script>
